import { render, staticRenderFns } from "./afterDescription.vue?vue&type=template&id=52ac3302&scoped=true&"
import script from "./afterDescription.vue?vue&type=script&lang=js&"
export * from "./afterDescription.vue?vue&type=script&lang=js&"
import style0 from "./afterDescription.vue?vue&type=style&index=0&id=52ac3302&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ac3302",
  null
  
)

export default component.exports