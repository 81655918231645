<template>
  <div class="product_accessories">
    <div class="product_accessories_form">
      <div class="price_description_add">
        <el-button type="primary" @click="addEnclosure">+添加附件</el-button>
        <el-button @click="deleteBatch">
          批量删除
          <i class="el-icon-delete el-icon--right" style="margin-left: 8px"></i>
        </el-button>
      </div>
      <el-table
        @selection-change="handleSelectionChange"
        :data="enclosureList"
        class="ly_table"
        ref="multipleTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
      >
        <el-table-column align="center" type="selection" width="55" />
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column align="center" prop="productFileName" label="附件名称"></el-table-column>
        <el-table-column align="center" prop="productFileIntro" label="附件简介"></el-table-column>
        <el-table-column align="center" label="操作" width="240px">
          <template slot-scope="scope">
            <button-table
              content="下载"
              @buttonClick="downloadFile(scope.row)"
              icon="jr-iconjr-icon-download"
            />
            <button-table
              content="查看"
              icon="jr-iconjr-icon-eye"
              @buttonClick="seeClick(scope.row)"
            />
            <button-table
              @buttonClick="editItem(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteClick(scope.$index, scope.row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="添加附件" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div>
        <el-form ref="dataForm" :model="dataForm" label-width="100px">
          <el-form-item label="附件简介：" prop="productFileIntro">
            <el-input
              type="textarea"
              :autosize="{ minRows: 6 }"
              placeholder="请输入发票信息"
              v-model="dataForm.productFileIntro"
            />
          </el-form-item>
          <el-form-item label="附件上传：" prop="productFileName">
            <div class="brn_input">
              <el-upload
                action="'"
                ref="my-upload"
                :multiple="false"
                :file-list="fileList"
                :on-change="handleChange"
                :before-upload="beforeAvatarUpload"
                :http-request="upload"
                accept=".pdf"
              >
                <el-button type="info">上传文件</el-button>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelClick">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="预览文件"
      :visible.sync="isViewPdf20"
      :before-close="handleClose"
      :fullscreen="true"
    >
      <iframe :src="fileUrl" frameborder="0" style="width: 98vw; height: 90vh"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import {
  enclosureData,
  addFile,
  deleteFile,
  updataFile,
  getFindId,
  getUrlStr,
} from '../../../api/product'
import ButtonTable from '../../../components/ButtonTable.vue'
import { uploadFile } from '@/api/common'
import axios from 'axios'
import { URL } from '../../../../config'
export default {
  name: 'productAccessories',
  props: {
    productCode: {
      type: String,
    },
  },
  components: {
    ButtonTable,
  },
  data() {
    return {
      enclosureList: [], //产品附件表格数据
      dialogVisible: false, //弹出框状态
      dialogVisibleType: 0,
      multipleTable: [],
      dataForm: {
        productCode: '',
        productFileIntro: '',
        productFileKey: '',
        fileString: '', //文件上传数组
        timestamp: new Date().getTime(), // 当前时间戳
      },
      fileList: [], //编辑附件回显
      isViewPdf20: false,
      fileUrl: '',
      token: '',
      selectionVal: [],
    }
  },
  created() {
    if (this.productCode) {
      this.getEnclosureList()
    }
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem('token')
    },
  },
  methods: {
    //下载附件单个
    async downloadFile(row) {
      // const URL = "http://192.168.0.12:8085/";
      const url = row.productFileKey
      axios({
        method: 'GET',
        url: `${URL}/public/downloadUrlFile`,
        params: { urlStr: url },
        responseType: 'blob',
        headers: {
          // 设置请求头
          Authorization: this.Authorization,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: `application/msword`,
          })
          let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载'链接
          a.download = row.productFileKey.substring(
            row.productFileKey.lastIndexOf('/') + 1
          ) //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
          document.body.removeChild(a) //释放标签
        })
        .catch((err) => {
          //this.$message.error('文件下载失败')
        })
    },
    //只能上传一个文件上传第二次显示第二次上传
    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }
    },
    //查看附件
    async seeClick(row) {
      this.fileUrl = row.url
      // this.isViewPdf20 = true
      window.open(this.fileUrl); 
    },
    //编辑附件
    async editItem(row) {
      this.dialogVisible = true
      Object.assign(this.dataForm, row)
      const res = await getFindId({ id: row.id })
      if (res.code == 200) {
        this.fileList.push({
          name: row.productFileName,
          url: res.data.fileKey[0],
        })
      } else {
        this.$message.error('网络错误请稍候重试!')
      }
      this.dialogVisibleType = 1
    },
    //勾选
    handleSelectionChange(val) {
      if (!this.productCode) {
        this.selectionVal = val
      } else {
        let arr = []
        val.forEach((item) => {
          arr.push(item.id)
        })
        this.multipleTable = arr
      }
    },
    //批量删除
    async deleteBatch() {
      if (!this.productCode) {
        if (this.selectionVal.length < 1) {
          this.$message({
            type: 'warning',
            message: '请选择需要删除的数据项',
          })
          return
        }
        this.enclosureList = this.enclosureList.filter(
          (item) =>
            !this.selectionVal.some((el) => el.timestamp === item.timestamp)
        )
        this.$emit('newClick', this.enclosureList)
      } else {
        if (this.multipleTable.length < 1) {
          this.$message({
            type: 'warning',
            message: '请选择需要删除的数据项',
          })
          return
        }
        const res = await deleteFile(this.multipleTable)
        if (res.code == 200) {
          this.getEnclosureList()
          this.$message.success('删除成功')
        }
      }
    },
    //删除附件
    async deleteClick(index, row) {
      if (!this.productCode) {
        this.enclosureList.splice(index, 1)
        this.$emit('newClick', this.enclosureList)
      } else {
        const array = []
        array.push(row.id)
        const res = await deleteFile(array)
        if (res.code == 200) {
          this.getEnclosureList()
          this.$message.success('删除成功')
        }
      }
    },
    //限制上传类型
    beforeAvatarUpload(file) {
      let fileName = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = fileName === 'pdf'
      if (!extension) {
        this.$message({
          message: '上传文件只能是pdf格式!',
          type: 'warning',
        })
        this.clearFiles()
        this.dataForm.fileString = ''
      }
    },
    //弹出层取消
    cancelClick() {
      this.dialogVisible = false
      this.clearFiles()
    },
    //清空上传的文件
    clearFiles() {
      this.$refs['my-upload'].clearFiles()
      this.dataForm = {
        productCode: '',
        productFileIntro: '',
        productFileKey: '',
        url: '',
        fileString: '', //文件上传数组
        timestamp: new Date().getTime(), // 当前时间戳
      }
      this.fileList = []
    },
    //提交新增、修改
    async submit() {
      this.dataForm.productCode = this.productCode
      if (!this.productCode) {
        this.enclosureList.push(this.dataForm)
        this.dialogVisible = false
        this.clearFiles()
        this.$emit('newClick', this.enclosureList)
      } else {
        if (this.dialogVisibleType === 0) {
          const res = await addFile(this.dataForm)
          if (res.code == 200) {
            this.dialogVisible = false
            this.clearFiles()
            this.getEnclosureList()
            this.$message.success('新增成功')
          }
        } else {
          const res = await updataFile(this.dataForm)
          if (res.code == 200) {
            this.dialogVisible = false
            this.clearFiles()
            this.getEnclosureList()
            this.$message.success('修改成功')
          }
        }
      }
    },
    //文件上传接口
    async upload(params) {
      const form = new FormData()
      form.append('files', params.file)
      const res = await uploadFile(form)
      if (res.code == 200) {
        const tt = res.data.fileAddr
        const num = tt.concat(this.dataForm.fileString)
        this.dataForm.fileString = num.join(',')
        const str = this.dataForm.fileString.substring(
          0,
          this.dataForm.fileString.length - 1
        )
        if (this.dialogVisibleType === 0) {
          this.dataForm.productFileKey = str
        } else {
          this.dataForm.productFileKey =
            str +
            ',' +
            this.dataForm.productFileKey.substring(
              0,
              this.dataForm.productFileKey.length - 1
            )
        }
        this.dataForm.productFileName = params.file.name
        this.dataForm.url = res.data.fileLook[0]
        this.fileList.push({
          name: params.file.name,
          url: res.data.fileLook[0],
        })
      }
    },
    //弹出框关闭
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
          this.clearFiles()
        })
        .catch((_) => {})
    },
    //添加附件
    addEnclosure() {
      this.dialogVisible = true
      this.dialogVisibleType = 0
    },
    //获取产品附件表格
    async getEnclosureList() {
      const queryInfo = {
        productCode: this.productCode,
      }
      const res = await enclosureData(queryInfo)
      if (res.code == 200) {
        this.enclosureList = res.data
      }
    },
  },
  mounted() {
    document.getElementsByClassName(
      'product_accessories_form'
    )[0].style.height = document.body.clientHeight - 208 + 'px'
  },
}
</script>

<style lang="less" scoped>
.product_accessories {
  width: 100%;
  overflow: hidden;
  .price_description_add {
    padding-top: 10px;
  }
  .product_accessories_form {
    width: 100%;
    // height: 680px;
    overflow: auto;
  }
}
</style>
