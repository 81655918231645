<template>
  <div class="upload_video" id="upload_video">
    <div v-if="videoList.length" class="upload_video_flex">
      <div class="po_relive" v-for="(item, index) in videoList" :key="index">
        <video
          v-if="item"
          ref="videoRef"
          style="
            width: 238px;
            margin-top: 20px;
            height: 134px;
            margin-right: 20px;
          "
          :src="item"
          id="video"
          controls
          autoplay
        ></video>
        <div class="po_icon" @click="handleRemove(index)">
          <i class="el-icon-error"></i>
        </div>
      </div>
    </div>
    <div class="upload_flex">
      <el-upload
        action=""
        list-type="picture-card"
        :http-request="uploadVideo"
        :file-list="fileList"
        :on-change="handleChange"
        multiple
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-progress
        class="progress"
        :text-inside="true"
        :stroke-width="18"
        :percentage="progress"
        status="success"
      ></el-progress>
      <span style="color: #bebebe"
        >建议视频整体尺寸为1920*1080 px，大小不超过20MB，可上传多个视频</span
      >
    </div>
  </div>
</template>
<script>
import TcVod from "vod-js-sdk-v6";
import { uploadsign } from "@/api/common";

export default {
  components: {},
  props: {
    videos: {
      type: Array,
    },
    videosId: {
      type: Array,
    },
  },
  data() {
    return {
      hideUpload: false,
      // 文件列表
      fileList: [],
      // 上传成功后的地址
      videoURL: "",
      // 进度条百分比
      progress: 0,
      // base64图片地址  注：这个是项目需要设置一个默认的视频封面，不需要的忽略就行
      firstImage: "",
      // 上传视频获取成功后拿到的fileID【备用】
      fileId: "",
      videoList: [], //视频链接问题
      fileIdList: [], //视频id数组
    };
  },

  created() {
    if (this.videos) {
      this.videos.forEach((el) => {
        this.videoList.push(el.url);
      });
      this.progress = 100;
      this.fileIdList = this.videosId;
    }
  },
  watch: {
    // 注释这里就不会添加两条视频
    videos: {
      handler(newVal, oldVal) {
        if (newVal) {
          newVal.forEach((el) => {
            this.videoList.push(el.url);
          });
          this.progress = 100;
          this.fileIdList = this.videosId;
        }
      },
    },
  },
  methods: {
    // 获取签名  这里的签名请求是由后端提供的，只需要拿到后端给的签名请求即可
    getVodSignature() {
      return uploadsign().then((response) => {
        return response.data;
      });
    },
    // 文件列表改变时  将文件列表保存到本地
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    // 点击上传时
    submitUpload() {
      if (this.fileList.length < 1)
        return this.$MessageBox("请先选取视频，再进行上传", "提示");
      this.uploadVideo();
    },
    // 自定义上传
    uploadVideo(e) {
      // 当
      if (this.fileList.length < 1) {
        window.alert("您还没有选取文件");
      } else {
        //必须以函数的形式返回  sdk参数限制
        const getSignature = async () => {
          const data = await this.getVodSignature();

          return data;
        };

        const tcVod = new TcVod({
          getSignature: getSignature, // 获取上传签名的函数
        });
        // 获取通过elementui上传到本地的文件  因为参数类型必须为file 不能直接以对象的形式传输
        const mediaFile = this.fileList[0].raw;
        const uploader = tcVod.upload({
          mediaFile: mediaFile,
        });

        // 监听上传进度
        this.hideUpload = true;
        uploader.on("media_progress", (info) => {
          this.progress = parseInt(info.percent * 100);
        });
        // 上传结束时，将url存到本地
        uploader.done().then((doneResult) => {
          this.fileIdList.push(doneResult.fileId);
          // this.videoURL = doneResult.video.url
          this.videoList.push(doneResult.video.url);
          this.$emit("uploadOneImg", this.fileIdList);
        });
      }
    },

    // 点击删除时
    handleRemove(index) {
      this.videoList.splice(index, 1);
      this.fileIdList.splice(index, 1);
      this.$emit("removeImg", this.fileIdList);
    },
  },
};
</script>
<style scoped lang="less">
.upload_video_flex {
  display: flex;
  align-items: center;
}
.upload_video {
  width: 600px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.upload_flex {
  position: relative;
  top: 20px;
  display: flex;
  flex-direction: column;
}
.po_relive {
  width: 238px;
  height: 154px;
  position: relative;
  z-index: 1;
  margin-right: 15px;
  .po_icon {
    position: absolute;
    top: -2px;
    right: -15px;
    font-size: 24px;
    color: #448aff;
    z-index: 999;
    cursor: pointer;
  }
}
.progress {
  width: 238px;
  margin-top: 5px;
}
.hide ::v-deep .el-upload--picture-card {
  display: none;
}
/deep/.el-upload--picture-card {
  width: 238px;
  height: 134px;
  border-radius: 2px;
  border: none;
  text-align: center;
  line-height: 134px;
  font-size: 14px;
  color: #4e93fb;
  display: inline-block;
  background-color: #f4f4f4;
}
/deep/.el-upload-list__item {
  width: 238px;
  height: 134px;
  border: none;
  display: none;
}
/deep/.el-dialog {
  width: 500px;
  height: 500px !important;
}
/deep/.el-dialog__body {
  width: 500px;
  height: 500px !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
v-deep .el-upload-list--picture-card {
  margin: 0;
  display: inline;
  vertical-align: top;
  display: none;
}
/deep/.el-dialog__header {
  padding: 0;
}
.img {
  width: 450px;
  height: 450px;
}
</style>
