import { render, staticRenderFns } from "./productAccessories.vue?vue&type=template&id=2becfe90&scoped=true&"
import script from "./productAccessories.vue?vue&type=script&lang=js&"
export * from "./productAccessories.vue?vue&type=script&lang=js&"
import style0 from "./productAccessories.vue?vue&type=style&index=0&id=2becfe90&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2becfe90",
  null
  
)

export default component.exports