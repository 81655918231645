<template>
  <div class="after_description">
    <div class="after_description_height">
      <el-form :model="afterInfo" label-position="left" ref="ruleForm" label-width="100px">
        <el-form-item label="发票信息:" prop="solutionInvoice">
          <el-input
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 4 }"
            placeholder="请输入发票信息"
            v-model="afterInfo.solutionInvoice"
          />
        </el-form-item>
        <el-form-item label="服务保障:" prop="serviceGuarantee">
          <el-input
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 4 }"
            placeholder="请输入服务保障"
            v-model="afterInfo.serviceGuarantee"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom" v-if="this.enterpriseType">
      <el-button type="primary" @click="afterUpdate">确定</el-button>
      <el-button type="info" @click="cancelClick">取消</el-button>
    </div>
  </div>
</template>

<script>
import { saveAfterSales } from '../../../api/product'
export default {
  name: 'afterDescription',
  props: {
    afterInfo: {
      type: Object,
    },
    enterpriseType: {
      type: Number,
    },
  },
  methods: {
    //取消
    cancelClick() {
      this.$router.go(-1)
    },
    //编辑售后说明
    async afterUpdate() {
      const res = await saveAfterSales(this.afterInfo)
      if (res.code == 200) {
        this.$message.success('售后说明修改成功')
        this.$router.go(-1)
      } else {
        this.$message.error(res.msg)
      }
    },
  },
  mounted() {
    document.getElementsByClassName(
      'after_description_height'
    )[0].style.height = document.body.clientHeight - 260 + 'px'
  },
}
</script>

<style lang="less" scoped>
.after_description {
  width: 100%;
  overflow: hidden;
}

.after_description_height {
  // height: 680px;
  overflow: auto;
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}

// /deep/ .el-tabs__content{
//   height:750px
// }
</style>
