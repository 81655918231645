<template>
  <div class="price_description">
    <div class="price_description_form">
      <div class="price_description_add">
        <el-button type="primary" @click="addPrice">+添加价格</el-button>
      </div>
      <el-table
        :data="priceList"
        class="ly_table"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
      >
        <el-table-column align="center" type="selection" width="55" />
        <el-table-column
          align="center"
          prop="productSpecifications"
          label="产品规格"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="number"
          label="数量/时长"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="price"
          label="含税价格"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="currency"
          label="币种"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remarks"
          label="备注"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="120px">
          <template slot-scope="scope">
            <button-table
              @buttonClick="editItem(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteClick(scope.$index, scope.row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
      </el-table>
    </div>

    <el-dialog
      title="价格说明"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="产品规格：" prop="productSpecifications">
            <el-input v-model="ruleForm.productSpecifications"></el-input>
          </el-form-item>
          <el-form-item label="数量时长：" prop="number">
            <el-input v-model="ruleForm.number" type="number"></el-input>
          </el-form-item>
          <el-form-item label="含税价格：" prop="price">
            <el-input v-model="ruleForm.price" type="number"></el-input>
          </el-form-item>
          <el-form-item label="币种：" prop="currency">
            <el-radio-group v-model="ruleForm.currency">
              <el-radio
                v-for="(item, index) in currencyList"
                :key="index"
                :label="item.codeName"
                >{{ item.codeName }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注：" prop="remarks">
            <el-input
              v-model="ruleForm.remarks"
              type="textarea"
              :autosize="{ minRows: 4 }"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeClick">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCurrency,
  getPrice,
  addPriceList,
  upDataPriceList,
  deletePriceList,
} from "../../../api/product";
import ButtonTable from "../../../components/ButtonTable.vue";
export default {
  name: "priceDescription",
  components: {
    ButtonTable,
  },
  props: {
    productCode: {
      type: String,
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibleType: 0,
      ruleForm: {
        productCode: "",
        productSpecifications: "",
        number: "",
        price: "",
        currency: "",
        remarks: "",
      },
      priceList: [],
      currencyList: [], //币种
      code: "018", //币种code
      rules: {
        productSpecifications: [
          { required: true, message: "请输入产品规格", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getCodeByStatus();
    if (this.productCode) {
      this.getPriceList();
    }
  },
  methods: {
    //编辑单个产品间隔
    editItem(row) {
      this.dialogVisible = true;
      this.dialogVisibleType = 1;
      this.ruleForm = row;
    },
    //删除单个产品价格
    async deleteClick(index, row) {
      if (!this.productCode) {
        this.priceList.splice(index, 1);
        this.$emit("pricelClick", this.priceList);
      } else {
        const queryInfo = {
          id: row.id * 1,
        };
        const res = await deletePriceList(queryInfo);
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getPriceList();
        }
      }
    },
    //获取产品价格数据
    async getPriceList() {
      const queryInfo = {
        productCode: this.productCode,
      };
      const res = await getPrice(queryInfo);
      if (res.code == 200) {
        this.priceList = res.data;
      }
    },
    //提交页面信息
    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.productCode = this.productCode;
          if (!this.productCode) {
            this.priceList.push(this.ruleForm);
            this.closeClick();
            this.$emit("pricelClick", this.priceList);
          } else {
            if (this.dialogVisibleType === 0) {
              const res = await addPriceList(this.ruleForm);
              if (res.code == 200) {
                this.closeClick();
                this.$message.success("新增成功");
                this.getPriceList();
              }
            } else {
              const res = await upDataPriceList(this.ruleForm);
              if (res.code == 200) {
                this.closeClick();
                this.$message.success("编辑成功");
                this.getPriceList();
              }
            }
          }
        }
      });
    },
    //取消页面
    closeClick() {
      this.dialogVisible = false;
      this.ruleForm = {
        productCode: "",
        productSpecifications: "",
        number: "",
        price: "",
        currency: "",
        remarks: "",
      };
    },
    //获取币种的枚举
    async getCodeByStatus() {
      const queryInfo = {
        code: this.code,
      };
      const res = await getCurrency(queryInfo);
      if (res.code == 200) {
        this.currencyList = res.data;
      }
    },
    //点击新增
    addPrice() {
      this.dialogVisible = true;
      this.dialogVisibleType = 0;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
          this.closeClick();
        })
        .catch((_) => {});
    },
  },
  mounted() {
    document.getElementsByClassName(
      "price_description_form"
    )[0].style.minHeight = document.body.clientHeight - 262 + "px";
  },
};
</script>

<style lang="less" scoped>
.price_description {
  /deep/input[type="number"] {
    -moz-appearance: textfield !important;
  }
  /deep/input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  /deep/input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  .price_description_add {
    padding-top: 10px;
  }
  .price_description_form {
    width: 100%;
    // height: 680px;
    overflow: auto;
  }
  .detailed_information_btn {
    padding-top: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    border-top: 1px solid #eee;

    button {
      padding: 6px 30px;
    }
  }
}
</style>
